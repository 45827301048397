import {toast} from 'react-toastify';
import axios from '../../utils/axios';

export const CREATE_NEW_ANNOUNCEMENT = 'CREATE_NEW_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const SELECT_ANNOUNCEMENT = 'SELECT_ANNOUNCEMENT';
export const CLEAR_SELECTED_ANNOUNCEMENT = 'CLEAR_SELECTED_ANNOUNCEMENT';
export const FETCHED_ANNOUNCEMENTS = 'FETCHED_ANNOUNCEMENTS';
export const REARRANGE_ANNOUNCEMENTS = 'REARRANGE_ANNOUNCEMENTS';

const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const getHeaders = () => {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  return headers;
};

export const creatAnnouncement = (data) => async (dispatch) => {
  try {
    const headers = getHeaders();

    const response = await axios.post('/announcements', data, {headers});

    dispatch({type: CREATE_NEW_ANNOUNCEMENT, payload: response.data});
    toast.success('Announcement Added !', toastConfig);
  } catch (err) {
    toast.error(err.message || 'Failed to create announcement', toastConfig);
  }
};

export const fetchAnnouncements = async (dispatch) => {
  try {
    const response = await axios.get('/announcements');
    const payload = {...response.data};

    dispatch({type: FETCHED_ANNOUNCEMENTS, payload});
  } catch (err) {
    toast.error(err.message || 'Failed to get announcements', toastConfig);
  }
};

export const updatedAnnouncement = (data, id) => async (dispatch) => {
  try {
    const headers = getHeaders();

    const url = `/announcements/${id}`;
    const response = await axios.put(url, data, {headers});

    dispatch({type: UPDATE_ANNOUNCEMENT, payload: response.data});
    toast.success('Announcement Updated !', toastConfig);
  } catch (err) {
    toast.error(err.message || 'Failed to update announcement', toastConfig);
  }
};

export const deleteAnnouncement = (id) => async (dispatch) => {
  try {
    const headers = getHeaders();

    const response = await axios.delete(`/announcements/${id}`, {headers});

    dispatch({type: DELETE_ANNOUNCEMENT, payload: response.data?.id || id});
    toast.success('Announcement Deleted !', toastConfig);
  } catch (err) {
    toast.error(err.message || 'Failed to update announcement', toastConfig);
  }
};

export const updateAnnouncementsOrder = (order) => async (dispatch) => {
  try {
    const headers = getHeaders();

    const url = '/announcements/announcements-reorder';
    await axios.patch(url, order, {headers});

    dispatch({type: REARRANGE_ANNOUNCEMENTS, payload: order});
    toast.success('Announcement Re-ordered !', toastConfig);
  } catch (err) {
    toast.error(err.message || 'Failed to update announcement', toastConfig);
  }
};
