export const CREATE_PRODUCT_ACTION = '[Product Action] Create Product';
export const CONFIRMED_CREATE_PRODUCT_ACTION =
  '[Product Action] Confirmed Create Product';
export const GET_PRODUCTS = '[Product Action] Get Products';
export const CONFIRMED_GET_PRODUCTS = '[Product Action] Confirmed Get Products';
export const EDIT_PRODUCT_ACTION = '[Product Action] Edit Product';
export const CONFIRMED_EDIT_PRODUCT_ACTION =
  '[Product Action] Confirmed Edit Product';
export const CONFIRMED_DELETE_PRODUCT_ACTION =
  '[Product Action] Confirmed Delete Product';
