export const MenuList = [
  {
    title: 'Dashboard',
    iconStyle: 'bi bi-grid',
    to: '',
  },
  // {
  //   title: 'Manage Products',
  //   iconStyle: 'bi bi-shop-window',
  //   content: [
  //     {
  //       title: 'All Products',
  //       to: 'products',
  //     },
  //     {
  //       title: 'Add New Product',
  //       to: 'manage-product',
  //     },
  //   ],
  // },
  {
    title: 'Manage Products',
    iconStyle: 'bi bi-shop-window',
    to: 'products',
  },
  {
    title: 'Manage Categories',
    iconStyle: 'bi bi-pie-chart',
    to: 'categories',
  },
  {
    title: 'Manage Orders',
    to: 'orders',
    iconStyle: 'bi bi-file-earmark-break',
  },
  {
    title: 'Manage Coupons',
    to: 'coupons',
    iconStyle: 'bi bi-upc-scan',
  },
  {
    title: 'Announcements',
    iconStyle: 'bi bi-megaphone',
    to: 'announcements',
  },
  {
    title: 'Front Page',
    classsChange: 'menu-title',
  },
  {
    title: 'Shop Page',
    iconStyle: 'bi bi-shop',
    to: '/',
  },
];
