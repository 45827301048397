import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import swal from 'sweetalert';
import {useSelector, useDispatch} from 'react-redux';

import {
  getCategoriesAction,
  deleteCategoryAction,
  createCategoryAction,
  closeModel,
  responseErrorAction,
  updateCategoryAction,
} from '../../../../store/actions/CategoryActions';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CategoryTable = () => {
  const allCategories = useSelector((state) => state.categories.categories);
  const responseError = useSelector((state) => state.categories.responseError);
  const isNewCategoryadded = useSelector(
    (state) => state.categories.modelState
  );

  const [imageUpload, setImageUpload] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (responseError?.success == false) {
      console.log(responseError);
      swal(
        `Error ${
          responseError?.err?.statusCode ? responseError.err.statusCode : ''
        }`,
        `${responseError?.err?.message ? responseError.err.message : ''}`,
        'error'
      );
      setAddCard(false);
      setIsLoading(false);
      dispatch(responseErrorAction({}));
    }
    if (responseError?.success == true) {
      swal(
        `Done`,
        `${
          responseError?.response?.message ? responseError.response.message : ''
        }`,
        'success'
      );
      dispatch(responseErrorAction({}));
    }
    if (isNewCategoryadded) {
      setAddCard(false);
      setIsLoading(false);
      dispatch(closeModel());
      // swal('Done', '', 'success');
      toast.success('Updated!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isNewCategoryadded, responseError]);

  // delete data
  const handleDeleteClick = (e, contentId) => {
    e.preventDefault();
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this category?',
      icon: 'warning',
      type: 'warning',
      dangerMode: true,
      buttons: {
        confirm: true,
        cancel: true,
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        dispatch(deleteCategoryAction(contentId));
      }
    });
  };

  //Modal box
  const [addCard, setAddCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editContentData, setEditContentData] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, content) => {
    event.preventDefault();
    setEditContentData(content);
    setAddCard(true);
  };
  const handleCloseModel = () => {
    setAddCard(false);
    setIsLoading(false);
    setEditContentData(null);
    setImageUpload(null);
  };

  return (
    <>
      <div className="col-12">
        <Modal className="modal fade" show={addCard} onHide={handleCloseModel}>
          <div className="" role="document">
            <div className="">
              <Formik
                initialValues={{
                  title: editContentData ? editContentData.title : '',
                  image: '',
                }}
                onSubmit={async (values) => {
                  setIsLoading(true);
                  if (values.title !== '') {
                    const formData = new FormData();
                    let providedData = {};
                    if (editContentData) {
                      for (const [key, value] of Object.entries(values)) {
                        if (
                          editContentData[key] == value ||
                          editContentData[key] == ''
                        ) {
                        } else {
                          if (key !== 'image') {
                            formData.set(key, value);
                            providedData[key] = value;
                          } else if (key == 'image' && imageUpload) {
                            formData.set('image', imageUpload);
                            providedData['image'] = imageUpload;
                          }
                        }
                      }
                      if (Object.entries(providedData).length !== 0) {
                        dispatch(
                          updateCategoryAction(formData, editContentData.id)
                        );
                      } else {
                        setIsLoading(false);
                        swal('Oops', 'Nothing Changed!', 'error');
                      }
                    } else {
                      for (const [key, value] of Object.entries(values)) {
                        if (key == 'image' && imageUpload) {
                          formData.set('image', imageUpload);
                        } else {
                          formData.set(key, value);
                        }
                      }
                      dispatch(createCategoryAction(formData));
                    }
                  } else {
                    setIsLoading(false);
                    swal('Oops', 'Title is Required!', 'error');
                  }
                }}
              >
                <Form encType="multipart/form-data">
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">
                      {editContentData ? 'Edit' : 'Add'} Category
                    </h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => handleCloseModel()}
                      data-dismiss="modal"
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Title</label>
                          <div className="contact-name">
                            <Field
                              id="title"
                              className="form-control"
                              autoComplete="off"
                              name="title"
                              placeholder="Category Name"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Image</label>
                          <div className="contact-name">
                            {editContentData?.image ? (
                              <img
                                src={editContentData.image}
                                height="120"
                                width="120"
                                className="mb-2 border rounded"
                              />
                            ) : (
                              ''
                            )}
                            <input
                              id="image"
                              type="file"
                              className="form-control"
                              autoComplete="off"
                              name="image"
                              onChange={(e) =>
                                setImageUpload(e.target.files[0])
                              }
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{overflow: 'hidden', position: 'relative'}}
                      disabled={isLoading ? true : false}
                    >
                      {editContentData ? 'Update' : 'Add'}
                      {isLoading ? (
                        <span className="lds-ripple">
                          <span></span>
                          <span></span>
                        </span>
                      ) : (
                        ''
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsLoading(false);
                        setAddCard(false);
                        setEditContentData(null);
                      }}
                      disabled={isLoading ? true : false}
                      className="btn btn-danger"
                    >
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </Modal>
        <div className="card">
          <div className="card-header gradient-dark">
            <div>
              <h1 className="display-6 text-white">
                All Categories <br />
              </h1>
              <h5 className="text-white">Total - {allCategories.length}</h5>
            </div>
            <Link
              className="btn btn-light shadow"
              onClick={() => {
                setEditContentData(null);
                setAddCard(true);
                setImageUpload(null);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
                height="14"
                width="14"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>{' '}
              Add New Category
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCategories.map((content, index) => (
                      <tr key={index} className="hover-bg-secondary-1">
                        <td width="100">
                          <img
                            className="rounded border"
                            width="60"
                            height="60"
                            src={content.image || '/default-logo.jpg'}
                            style={{objectFit: 'cover'}}
                            alt=""
                          />
                        </td>
                        <td>{content.title}</td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Link
                              className="btn btn-secondary shadow sharp me-2 px-3"
                              onClick={(event) => {
                                setImageUpload(null);
                                handleEditClick(event, content);
                              }}
                              title="Edit Category"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 me-1"
                                height="14"
                                width="14"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                              </svg>{' '}
                              <small>Edit</small>
                            </Link>
                            <Link
                              className="btn btn-danger shadow sharp px-3"
                              onClick={(e) => {
                                handleDeleteClick(e, content.id);
                              }}
                              title="Delete Category"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 me-1"
                                height="14"
                                width="14"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>{' '}
                              <small>Delete</small>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default CategoryTable;
