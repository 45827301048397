import {
  createCategory,
  formatCategories,
  getCategories,
  deleteCategory,
  updateCategory,
} from '../../services/CategoryService';
import {
  CONFIRMED_CREATE_CATEGORY_ACTION,
  CONFIRMED_DELETE_CATEGORY_ACTION,
  CONFIRMED_EDIT_CATEGORY_ACTION,
  CONFIRMED_GET_CATEGORIES,
} from './CategoryTypes';

export function deleteCategoryAction(categoryId, history) {
  return (dispatch, getState) => {
    deleteCategory(categoryId).then((response) => {
      const isError = response.statusCode === 400;
      const success = !isError;
      const message = isError ? response.message : 'Successfuly Deleted';

      if (isError) {
        dispatch(responseErrorAction({success, err: {message}}));
      } else {
        dispatch(confirmedDeleteCategoryAction(categoryId));
        dispatch(responseErrorAction({success, response: {message}}));
      }
    });
  };
}

export function confirmedDeleteCategoryAction(categoryId) {
  return {
    type: CONFIRMED_DELETE_CATEGORY_ACTION,
    payload: categoryId,
  };
}

export function createCategoryAction(categoryData, history) {
  return (dispatch, getState) => {
    createCategory(categoryData).then((response) => {
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedCreateCategoryAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
export function responseErrorAction(error) {
  return {
    type: 'ERROR_OCCURD',
    payload: error,
  };
}
export function closeModel() {
  return {
    type: 'CLOSE_MODEL',
  };
}
export function getCategoriesAction() {
  return (dispatch, getState) => {
    getCategories().then((response) => {
      let categories = response.data;
      dispatch(confirmedGetCategoriesAction(categories));
    });
  };
}

export function confirmedCreateCategoryAction(singleCategory) {
  return {
    type: CONFIRMED_CREATE_CATEGORY_ACTION,
    payload: singleCategory,
  };
}

export function confirmedGetCategoriesAction(categories) {
  return {
    type: CONFIRMED_GET_CATEGORIES,
    payload: categories,
  };
}

export function confirmedUpdateCategoryAction(category) {
  return {
    type: CONFIRMED_EDIT_CATEGORY_ACTION,
    payload: category,
  };
}

export function updateCategoryAction(category, categoryId, history) {
  return (dispatch, getState) => {
    updateCategory(category, categoryId).then((response) => {
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedUpdateCategoryAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
