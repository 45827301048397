import {
  CONFIRMED_CREATE_PRODUCT_ACTION,
  CONFIRMED_DELETE_PRODUCT_ACTION,
  CONFIRMED_EDIT_PRODUCT_ACTION,
  CONFIRMED_GET_PRODUCTS,
  // CREATE_CATEGORY_ACTION,
} from '../actions/ProductTypes';

const initialState = {
  products: [],
  searchedProducts: [],
  editableProduct: null,
  booleanAction: false,
  responseError: {},
  searchQuery: null,
};

export default function ProductsReducer(state = initialState, actions) {
  if (actions.type === CONFIRMED_DELETE_PRODUCT_ACTION) {
    const products = [...state.products];
    const productIndex = products.findIndex(
      (product) => product.id === actions.payload
    );

    const searchedProducts = [...state.searchedProducts];
    const searchedProductIndex = searchedProducts.findIndex(
      (product) => product.id === actions.payload
    );

    products.splice(productIndex, 1);
    searchedProducts.splice(searchedProductIndex, 1);
    return {
      ...state,
      products,
      searchedProducts,
    };
  }

  if (actions.type === 'UPDATE_EDITABLE_PRODUCT_ACTION') {
    return {
      ...state,
      editableProduct: actions.payload,
    };
  }
  if (actions.type === CONFIRMED_EDIT_PRODUCT_ACTION) {
    const products = [...state.products];
    const productsIndex = products.findIndex(
      (product) => product.id === actions.payload.id
    );
    const searchedProducts = [...state.searchedProducts];
    const searchProductIndex = searchedProducts.findIndex(
      (product) => product.id === actions.payload.id
    );
    products[productsIndex] = actions.payload;
    searchedProducts[searchProductIndex] = actions.payload;
    return {
      ...state,
      products,
      searchedProducts,
      booleanAction: true,
    };
  }

  if (actions.type === CONFIRMED_CREATE_PRODUCT_ACTION) {
    const products = [...state.products];
    const product = actions.payload;
    products.push(product);
    const searchedProducts = [...state.searchedProducts];
    searchedProducts.push(product);
    return {
      ...state,
      products,
      searchedProducts,
      booleanAction: true,
    };
  }
  if (actions.type == 'BOOLEAN_ACTION') {
    return {
      ...state,
      booleanAction: actions.payload,
    };
  }
  if (actions.type === CONFIRMED_GET_PRODUCTS) {
    const allProducts = actions.payload;

    return {
      ...state,
      products: allProducts,
      searchedProducts: allProducts,
    };
  }
  if (actions.type === 'SEARCH_ITEM_ACTION') {
    if (actions.payload !== '') {
      let searchedProducts = state.products.filter((item) => {
        let title = item.title.toLowerCase();
        let searchedQuery = actions.payload.toLowerCase();
        return title.includes(searchedQuery);
      });
      return {
        ...state,
        searchedProducts,
        searchQuery: actions.payload,
      };
    } else {
      return {
        ...state,
        searchedProducts: state.products,
        searchQuery: null,
      };
    }
  }
  if (actions.type == 'ERROR_OCCURD') {
    return {
      ...state,
      responseError: actions.payload,
    };
  }
  return state;
}
