import React, {useContext, useEffect} from 'react';

/// React router dom
import {Routes, Route, Outlet, useNavigate} from 'react-router-dom';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';
//import Main from './layouts/Main';

import ScrollToTop from './layouts/ScrollToTop';
/// HOme
import Home from './pages/Home';

/// HOme
import Dashboard from './components/Dashboard/Home';

/// Pages

import Error404 from './pages/Error404';
import {ThemeContext} from '../context/ThemeContext';
import EditCategory from './components/Dashboard/EditCategory';
import EditCoupon from './components/Dashboard/EditCoupon';
import EditProducts from './components/Dashboard/EditProducts';
import ProductsAddEdit from './components/Dashboard/Products/ProductsAddEdit';
import Orders from './components/Dashboard/Orders';
import Announcements from './components/Dashboard/Announcements';

const Markup = () => {
  const location = window.location;
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname == '/login') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/login" element={<MainLayout />} />
        <Route path="dashboard" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="*" element={<Error404 />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="categories" element={<EditCategory />} />
          <Route path="products" element={<EditProducts />} />
          <Route path="manage-product" element={<ProductsAddEdit />} />
          <Route path="orders" element={<Orders />} />
          <Route path="coupons" element={<EditCoupon />} />
          <Route path="announcements" element={<Announcements />} />
        </Route>
        <Route exact path="/" element={<ShopLayout />}>
          <Route exact path="" element={<Home />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const {menuToggle} = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? 'menu-toggle' : ''}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{minHeight: window.screen.height - 45}}
      >
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer dashboard={true} />
    </div>
  );
}
function ShopLayout() {
  const {menuToggle} = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? 'menu-toggle' : ''}`}
    >
      <div
        className="content-body"
        style={{
          minHeight: window.screen.height - 45,
          marginLeft: '0',
          paddingTop: '0',
        }}
      >
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer dashboard={false} />
    </div>
  );
}

export default Markup;
