import {orderAnnouncements} from '../../utils/announcementsUtils';
import {
  CLEAR_SELECTED_ANNOUNCEMENT,
  CREATE_NEW_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  FETCHED_ANNOUNCEMENTS,
  REARRANGE_ANNOUNCEMENTS,
  SELECT_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from '../actions/AnnouncementActions';

const initialState = {
  list: [],
  selectedAnnouncement: {},
  order: [],
};

export default function AnnouncementsReducer(state = initialState, action) {
  if (action.type === CREATE_NEW_ANNOUNCEMENT) {
    const announcements = [...state.list];
    announcements.push(action.payload);
    return {...state, list: announcements};
  }

  if (action.type === FETCHED_ANNOUNCEMENTS) {
    const {announcements, order} = action.payload;

    const orderedAnnouncements = [];

    order.forEach((announcementId) => {
      const announcement = announcements.find(
        (announcement) => announcementId === announcement.id
      );

      if (announcement) orderedAnnouncements.push(announcement);
    });

    return {...state, list: orderedAnnouncements, order};
  }

  if (action.type === REARRANGE_ANNOUNCEMENTS) {
    const orderedAnnouncements = orderAnnouncements(action.payload, state.list);

    return {...state, list: orderedAnnouncements};
  }
  if (action.type === UPDATE_ANNOUNCEMENT) {
    const announcements = [...state.list];
    const index = announcements.findIndex(
      (announcement) => announcement.id === action.payload.id
    );
    announcements[index] = action.payload;
    return {...state, list: announcements};
  }

  if (action.type === DELETE_ANNOUNCEMENT) {
    const announcements = state.list.filter(
      (announcement) => announcement.id !== action.payload
    );
    return {...state, list: announcements};
  }

  if (action.type === SELECT_ANNOUNCEMENT) {
    return {...state, selectedAnnouncement: action.payload};
  }

  if (action.type === CLEAR_SELECTED_ANNOUNCEMENT) {
    return {...state, selectedAnnouncement: {}};
  }

  return state;
}
