import axios from '../utils/axios';

export async function getProducts() {
  const products = await axios({
    method: 'get',
    url: '/products',
  }).then(function (response) {
    return response;
  });
  return products;
}

export async function createProduct(productData) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  const singleProduct = await axios
    .post('/products', productData, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch(function (err) {
      return {
        success: false,
        err,
      };
    });
  return singleProduct;
}

export async function updateProduct(product, productId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  const updatedProduct = await axios
    .patch(`/products/${productId}`, product, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch(function (err) {
      return {
        success: false,
        err,
      };
    });

  return updatedProduct;
}

export async function deleteProduct(productId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  var config = {
    method: 'delete',
    url: `/products/${productId}`,
    headers,
  };
  const deletedProduct = await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  return deletedProduct;
}

// export function formatCategories(postsData) {
//   let posts = [];
//   for (let key in postsData) {
//     posts.push({...postsData[key], id: key});
//   }

//   return posts;
// }
