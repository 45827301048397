export const getAllProducts = (state) => {
  const productsArr = state.products.products;
  const updatedProducts = productsArr.map((item, index) => {
    item['number'] = 0;
    return item;
  });

  return updatedProducts;
};
export const getCartItems = (state) => {
  return state.shop.allProducts.filter((item) => item.number > 0);
};
export const getTotalIncludedItems = (state) => {
  const items = state.shop.allProducts.filter(
    (item) => item.number > 0 && item.isInclude
  );
  let totalItems = 0;
  items.map((item) => {
    totalItems = item.number + totalItems;
  });
  return totalItems;
};
export const getTotalCartPrice = (state) => {
  const appliedCoupon = state.coupons.appliedCoupon;

  let totalcost = 0;

  state.shop.allProducts.map((item, index) => {
    let thisPrice = item.number * item.price;
    totalcost = totalcost + thisPrice;
  });

  if (appliedCoupon) {
    if (appliedCoupon.type == 'fixed') {
      totalcost = totalcost - appliedCoupon.price;
    } else {
      const percentageOfTotal = (appliedCoupon.price / 100) * totalcost;
      totalcost = totalcost - percentageOfTotal;
    }
  }
  if (totalcost < 0) {
    totalcost = 0;
  }
  return parseFloat(totalcost).toFixed(2);
};
export const getExactTotalCartPrice = (state) => {
  let totalcost = 0;

  state.shop.allProducts.map((item, index) => {
    let thisPrice = item.number * item.price;
    totalcost = totalcost + thisPrice;
  });

  if (totalcost < 0) {
    totalcost = 0;
  }
  return parseFloat(totalcost).toFixed(2);
};
