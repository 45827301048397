import axios from '../utils/axios';

export async function getOrders() {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const orders = await axios({
    method: 'get',
    url: '/orders',
    headers,
  }).then(function (response) {
    return response;
  });
  return orders;
}
export async function postOrder(order) {
  const orderResponse = await axios
    .post('/orders', order)
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        err,
      };
    });
  return orderResponse;
}
