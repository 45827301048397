export function loadShopProducts(updatedProducts) {
  // console.log(state.products);

  return {
    type: 'LOAD_PRODUCTS',
    payload: updatedProducts,
  };
}
export function clearFilterAction(data) {
  return {
    type: 'CLEAR_ALL_FILTER_ACTION',
    payload: data,
  };
}
export function searchCategoryAction(category) {
  return {
    type: 'CATEGORY_SEARCH_ACTION',
    payload: category,
  };
}
export function addItemAction(productId) {
  return {
    type: 'ADD_ITEM_ACTION',
    payload: productId,
  };
}
export function removeItemAction(productId) {
  return {
    type: 'REMOVE_ITEM_ACTION',
    payload: productId,
  };
}
export function inputQuantityAction(id, qty) {
  return {
    type: 'INPUT_QUANTITY_ACTION',
    payload: {id, qty},
  };
}
export function searchItemAction(query) {
  return {
    type: 'SEARCH_ITEM_ACTION',
    payload: query,
  };
}
export function resetCartAction() {
  return {
    type: 'RESET_CART_ACTION',
    payload: [],
  };
}
export function removeCurrenItem(id) {
  return {
    type: 'REMOVE_CURRENT_ITEM_ACTION',
    payload: id,
  };
}
