import Select from 'react-select';
import {useField} from 'formik';

const MultiSelect = (props) => {
  const [_, state, {setValue, setTouched}] = useField(props.name);

  const onChange = (value) => setValue(value);

  return (
    <Select
      {...props}
      value={state?.value}
      isMulti
      onChange={onChange}
      onBlur={setTouched}
    />
  );
};

export default MultiSelect;
