import React from 'react';

const Footer = ({dashboard}) => {
  var d = new Date();
  return (
    <div
      className="footer"
      style={{paddingLeft: dashboard ? '17.1875rem' : '0'}}
    >
      <div className="copyright border-top">
        <p>
          Copyright © Designed &amp; Developed with{' '}
          <i className="bi bi-heart-fill text-danger"></i> by{' '}
          <a
            className="text-secondary"
            href="https://desolint.com/"
            target="_blank"
            rel="noreferrer"
          >
            Desol Int.
          </a>{' '}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
