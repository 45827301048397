const initialState = {
  coupons: [],
  appliedCoupon: null,
  responseError: {},
  modelState: false,
};

export default function CouponsReducer(state = initialState, actions) {
  if (actions.type === 'CONFIRMED_DELETE_COUPON_ACTION') {
    const coupons = [...state.coupons];
    const couponIndex = coupons.findIndex(
      (coupon) => coupon.id === actions.payload
    );

    coupons.splice(couponIndex, 1);

    return {
      ...state,
      coupons,
    };
  }
  if (actions.type === 'CONFIRMED_APPLY_COUPON_ACTION') {
    if (actions.payload) {
      return {
        ...state,
        appliedCoupon: actions.payload,
        responseError: {},
      };
    }
    return {
      ...state,
      appliedCoupon: null,
      responseError: {
        success: false,
        err: {
          statusCode: 404,
          message: 'Coupon not Found',
        },
      },
    };
  }
  if (actions.type === 'CONFIRMED_EDIT_COUPON_ACTION') {
    const coupons = [...state.coupons];
    const couponIndex = coupons.findIndex(
      (coupon) => coupon.id === actions.payload.id
    );

    coupons[couponIndex] = actions.payload;
    return {
      ...state,
      coupons,
      modelState: true,
    };
  }

  if (actions.type === 'CONFIRMED_CREATE_COUPON_ACTION') {
    const coupons = [...state.coupons];
    coupons.push(actions.payload);

    return {
      ...state,
      coupons,
      modelState: true,
    };
  }
  if (actions.type == 'ERROR_OCCURD') {
    return {
      ...state,
      responseError: actions.payload,
    };
  }
  if (actions.type == 'CLOSE_MODEL') {
    return {
      ...state,
      modelState: false,
    };
  }
  if (actions.type === 'CONFIRMED_GET_COUPONS') {
    return {
      ...state,
      coupons: actions.payload,
    };
  }
  return state;
}
