import {lazy, Suspense, useEffect} from 'react';

/// Components
import Index from './jsx';
import Error404 from './jsx/pages/Error404';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
// action
import {checkAutoLogin} from './services/AuthService';
import {isAuthenticated} from './store/selectors/AuthSelectors';
/// Style
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
// import {useDispatch} from 'react-redux';
import {getProductsAction} from './store/actions/ProductActions';
import {getCategoriesAction} from './store/actions/CategoryActions';
import {loadShopProducts} from './store/actions/ShopActions';
import {getAllProducts} from './store/selectors/ShopSelectors';
import {getOrdersAction} from './store/actions/OrderActions';
import {getCouponsAction} from './store/actions/CouponActions';
import {fetchAnnouncements} from './store/actions/AnnouncementActions';

const Home = lazy(() => import('./jsx/pages/Home'));

// const SignUp = lazy(() => import('./jsx/pages/Registration'));
// const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{location, navigate, params}} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const products = useSelector((state) => state.products.products);
  const updatedProducts = useSelector(getAllProducts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (props.isAuthenticated) {
        await dispatch(getOrdersAction());
        await dispatch(getCouponsAction());
      }
    })();
  }, [props.isAuthenticated]);

  useEffect(() => {
    (async () => {
      let location = window.location.pathname;

      if (
        location === '/dashboard' ||
        location === '/login' ||
        location.includes('dashboard') ||
        location.includes('login')
      ) {
        checkAutoLogin(dispatch, navigate);
      }
      // if (props.isAuthenticated && location.includes('login')) {
      //   navigate('/dashboard');
      // }
      await dispatch(getProductsAction());
      await dispatch(getCategoriesAction());
      await dispatch(fetchAnnouncements);
    })();
  }, []);

  useEffect(() => {
    dispatch(loadShopProducts(updatedProducts));
  }, [products]);

  let routeblog = (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Index />} />
    </Routes>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
