import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import swal from 'sweetalert';
import {useSelector, useDispatch} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getProductsAction,
  deleteProductAction,
  editableProductAction,
  updateProductAction,
  booleanAction,
  responseErrorAction,
  searchItemAction,
} from '../../../../store/actions/ProductActions';

const ProductsTable = () => {
  const allProducts = useSelector((state) => state.products.products);
  const searchedProducts = useSelector(
    (state) => state.products.searchedProducts
  );
  const expiryAction = useSelector((state) => state.products.booleanAction);
  const productSearchQuery = useSelector((state) => state.products.searchQuery);
  const responseError = useSelector((state) => state.products.responseError);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(editableProductAction(null));
  }, []);
  useEffect(() => {
    if (responseError?.success == false) {
      swal(
        `Error ${
          responseError?.err?.statusCode ? responseError.err.statusCode : ''
        }`,
        `${responseError?.err?.message ? responseError.err.message : ''}`,
        'error'
      );
      dispatch(responseErrorAction({}));
    }
    if (responseError?.success == true) {
      swal(
        `Done`,
        `${
          responseError?.response?.message ? responseError.response.message : ''
        }`,
        'success'
      );
      dispatch(responseErrorAction({}));
    }
    if (expiryAction) {
      // swal(`Updated`, ``, 'success');
      toast.success('Updated!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      dispatch(booleanAction(false));
    }
  }, [expiryAction, responseError]);

  // delete data
  const handleDeleteClick = (e, contentId) => {
    e.preventDefault();
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this Product?',
      icon: 'warning',
      type: 'warning',
      dangerMode: true,
      buttons: {
        confirm: true,
        cancel: true,
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        dispatch(deleteProductAction(contentId));
      }
    });
  };

  // Edit function button click to edit
  const handleEditClick = (event, content) => {
    event.preventDefault();
    dispatch(editableProductAction(content));
    navigate('/dashboard/manage-product');
  };

  // Search System
  const searchHandle = (query) => {
    if (query !== '' && query.length >= 1) {
      if (query.includes('restricted')) {
        query = '*';
      }
      dispatch(searchItemAction(query));
    } else {
      dispatch(searchItemAction(''));
    }
  };
  const searchButtonHandle = (e) => {
    const {search} = e.target;
    if (search.value !== '') {
      searchHandle(search.value);
    }
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header bg-secondary gradient-dark">
            <div>
              <h1 className="display-6 text-white">
                All Products <br />
              </h1>
              <h5 className="text-white">Total - {allProducts.length}</h5>
            </div>
            <Link
              className="btn btn-light shadow"
              to="/dashboard/manage-product"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
                height="14"
                width="14"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>{' '}
              Add New Product
            </Link>
          </div>
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchButtonHandle(e);
              }}
            >
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Product Name"
                  aria-label="Product Name"
                  aria-describedby="button-addon2"
                  name="search"
                  id="search"
                  defaultValue={productSearchQuery}
                  onChange={(e) => searchHandle(e.target.value)}
                />
                <button
                  className="btn btn-secondary "
                  type="submit"
                  id="button-addon2"
                >
                  Search
                </button>
              </div>
              {productSearchQuery && (
                <input
                  type="reset"
                  onClick={() => {
                    searchHandle('');
                  }}
                  className="btn btn-secondary btn-lg py-2 px-4 rounded-pill"
                  value={'Show All'}
                />
              )}
            </form>
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable ">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Is Included?</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedProducts.map((content, index) => (
                      <tr key={index} className="hover-bg-secondary-1">
                        <td width="100">
                          <img
                            className="rounded border"
                            width="60"
                            height="60"
                            src={content.image || '/default-logo.jpg'}
                            style={{objectFit: 'cover'}}
                            alt=""
                          />
                        </td>
                        <td width="400" className="text-black">
                          {content.title}
                        </td>
                        <td className="text-black">
                          <b>${content.price}</b>
                        </td>
                        <td width="150">
                          <Formik
                            initialValues={{
                              available: content.available,
                            }}
                            onSubmit={async (values) => {
                              dispatch(updateProductAction(values, content.id));
                            }}
                          >
                            {({handleSubmit}) => (
                              <Form onChange={handleSubmit}>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    role="switch"
                                    name="available"
                                    id={`flexSwitchCheckDefault-${content.id}`}
                                    checked={content.available}
                                  />
                                  <label className="form-check-label">
                                    {content.available
                                      ? 'Stock'
                                      : 'Out of Stock'}
                                  </label>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </td>
                        <td>
                          <Formik
                            initialValues={{
                              isInclude: content.isInclude,
                            }}
                            onSubmit={async (values) => {
                              dispatch(updateProductAction(values, content.id));
                            }}
                          >
                            {({handleSubmit}) => (
                              <Form onChange={handleSubmit}>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    role="switch"
                                    name="isInclude"
                                    id={`flexSwitchCheckInclude-${content.id}`}
                                    checked={content.isInclude}
                                  />
                                  <label className="form-check-label">
                                    {content.isInclude ? 'Yes' : 'No'}
                                  </label>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Link
                              className="btn btn-secondary shadow me-2 px-3"
                              onClick={(event) => {
                                handleEditClick(event, content);
                              }}
                              title="Edit Product"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 me-1"
                                height="14"
                                width="14"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                              </svg>{' '}
                              <small>Edit</small>
                            </Link>
                            <Link
                              className="btn btn-danger shadow px-3"
                              onClick={(e) => {
                                handleDeleteClick(e, content.id);
                              }}
                              title="Delete Product"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 me-1"
                                height="14"
                                width="14"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>{' '}
                              <small>Delete</small>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default ProductsTable;
