import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Link} from 'react-router-dom';
import {Autoplay} from 'swiper';

import 'swiper/css';
import {useDispatch, useSelector} from 'react-redux';
import {searchCategoryAction} from '../../../store/actions/ShopActions';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CategorySlider = () => {
  const allCategories = useSelector((state) => state.categories.categories);
  const searchedCategory = useSelector((state) => state.shop.searchedCategory);
  const dispatch = useDispatch();
  const colors = [
    'bg-red-200',
    'bg-lime-200',
    'bg-pink-200',
    'bg-green-200',
    'bg-orange-200',
    'bg-violet-200',
    'bg-blue-200',
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2 gap">
        <h4 className=" mb-0 cate-title">Categories</h4>
      </div>
      <div>
        {allCategories ? (
          <Swiper
            className="mySwiper-2 pb-5"
            modules={[Pagination, Navigation]}
            speed={1200}
            slidesPerView={5}
            spaceBetween={20}
            // loop={true}
            pagination={{clickable: true}}
            navigation
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
          >
            {allCategories.map((item, index) => {
              return (
                <SwiperSlide key={item.id} style={{height: 'auto'}}>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      // categorySearchHandler(item.id, item.title);
                      dispatch(searchCategoryAction(item));
                    }}
                    className={
                      searchedCategory?.title === item.title &&
                      searchedCategory?.queryType == 'category'
                        ? 'pe-none'
                        : ''
                    }
                    style={{height: '100%', display: 'block'}}
                  >
                    <div
                      className="cate-bx text-center "
                      style={{height: '100%'}}
                    >
                      <div
                        className={`rounded cat-wrap mb-0 ${
                          searchedCategory?.title === item.title &&
                          searchedCategory?.queryType == 'category'
                            ? 'bg-secondary pe-none'
                            : `hover-bg-secondary-2 bg-secondary-1`
                        }`}
                        style={{height: '100%'}}
                      >
                        {/* // colors[ // index > colors.length // ?
                        Math.floor(Math.random() * colors.length) // : index //
                        ] */}
                        <div className="card-body">
                          <div className="mb-3">
                            <img
                              src={item.image || '/default-logo.jpg'}
                              height="80px"
                              width="80px"
                              className={`${
                                searchedCategory?.title === item.title &&
                                searchedCategory?.queryType == 'category'
                                  ? 'shadow border border-primary rounded'
                                  : 'border border-transparent rounded'
                              }`}
                              style={{
                                transition: 'all ease 0.4s',
                                objectFit: 'cover',
                              }}
                            />
                          </div>

                          <h4
                            className={`mb-0 font-w600 font-planner ${
                              searchedCategory?.title === item.title &&
                              searchedCategory?.queryType == 'category'
                                ? 'text-white'
                                : ''
                            }`}
                            style={{transition: 'all ease 0.4s'}}
                          >
                            {item.title}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default CategorySlider;
