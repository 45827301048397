import {
  createProduct,
  formatCategories,
  getProducts,
  deleteProduct,
  updateProduct,
} from '../../services/ProductService';
import {
  CONFIRMED_CREATE_PRODUCT_ACTION,
  CONFIRMED_DELETE_PRODUCT_ACTION,
  CONFIRMED_EDIT_PRODUCT_ACTION,
  CONFIRMED_GET_PRODUCTS,
} from './ProductTypes';

export function deleteProductAction(productId, history) {
  return (dispatch, getState) => {
    deleteProduct(productId).then((response) => {
      dispatch(confirmedDeleteProductAction(productId));
      dispatch(
        responseErrorAction({
          success: true,
          response: {
            message: 'Successfuly Deleted',
          },
        })
      );
    });
  };
}

export function confirmedDeleteProductAction(productId) {
  return {
    type: CONFIRMED_DELETE_PRODUCT_ACTION,
    payload: productId,
  };
}

export function createProductAction(productData, history) {
  return (dispatch, getState) => {
    createProduct(productData).then((response) => {
      // const singleProduct = response;
      // dispatch(confirmedCreateProductAction(singleProduct));
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedCreateProductAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
export function responseErrorAction(error) {
  return {
    type: 'ERROR_OCCURD',
    payload: error,
  };
}
export function booleanAction(boolean) {
  return {
    type: 'BOOLEAN_ACTION',
    payload: boolean,
  };
}
export function editableProductAction(productData) {
  return {
    type: 'UPDATE_EDITABLE_PRODUCT_ACTION',
    payload: productData,
  };
}
export function getProductsAction() {
  return (dispatch, getState) => {
    getProducts().then((response) => {
      let products = response.data;
      dispatch(confirmedGetProductsAction(products));
    });
  };
}

export function confirmedCreateProductAction(singleProduct) {
  return {
    type: CONFIRMED_CREATE_PRODUCT_ACTION,
    payload: singleProduct,
  };
}

export function confirmedGetProductsAction(products) {
  return {
    type: CONFIRMED_GET_PRODUCTS,
    payload: products,
  };
}

export function confirmedUpdateProductAction(product) {
  return {
    type: CONFIRMED_EDIT_PRODUCT_ACTION,
    payload: product,
  };
}
export function searchItemAction(query) {
  return {
    type: 'SEARCH_ITEM_ACTION',
    payload: query,
  };
}

export function updateProductAction(product, productId, history) {
  return (dispatch, getState) => {
    updateProduct(product, productId).then((response) => {
      // dispatch(confirmedUpdateProductAction(response));
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedUpdateProductAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
