import React, {useEffect, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {useSelector} from 'react-redux';

const DeleteModal = ({onConfirm, onHide, show, loading}) => {
  const {selectedAnnouncement} = useSelector((state) => state.announcements);

  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    ref.current.innerHTML = `${selectedAnnouncement.text}`;
  }, [selectedAnnouncement]);

  return (
    <Modal className="modal fade" size="lg" show={show} onHide={onHide}>
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">
              Are you sure you want to delete ?
            </h4>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={onHide}
            >
              <span></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="add-contact-box" ref={ref}>
              {selectedAnnouncement.text}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              style={{overflow: 'hidden', position: 'relative'}}
              onClick={() => onConfirm(selectedAnnouncement.id)}
              disabled={loading}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={onHide}
              disabled={loading}
            >
              <i className="flaticon-delete-1"></i> Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
