export const CREATE_CATEGORY_ACTION = '[Category Action] Create Category';
export const CONFIRMED_CREATE_CATEGORY_ACTION =
  '[Category Action] Confirmed Create Category';
export const GET_CATEGORIES = '[Category Action] Get Categories';
export const CONFIRMED_GET_CATEGORIES =
  '[Category Action] Confirmed Get Categories';
export const EDIT_CATEGORY_ACTION = '[Category Action] Edit CATEGORY';
export const CONFIRMED_EDIT_CATEGORY_ACTION =
  '[Category Action] Confirmed Edit CATEGORY';
export const CONFIRMED_DELETE_CATEGORY_ACTION =
  '[Category Action] Confirmed Delete CATEGORY';
