import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import Edit from '../../../../icons/Edit';
import Delete from '../../../../icons/Delete';
import PlainTextConverter from '../../../../utils/HtmlParser';
import {
  SELECT_ANNOUNCEMENT,
  updateAnnouncementsOrder,
  updatedAnnouncement,
} from '../../../../store/actions/AnnouncementActions';

const AnnouncementTableItem = ({
  announcement = {},
  openModal,
  openDeleteModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(announcement.isActive);
  const {list: announcements} = useSelector((state) => state.announcements);
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch({type: SELECT_ANNOUNCEMENT, payload: announcement});
    openModal();
  };

  const handleDelete = () => {
    dispatch({type: SELECT_ANNOUNCEMENT, payload: announcement});
    openDeleteModal();
  };

  const handleActiveChangeStatus = async () => {
    setIsLoading(true);
    setIsActive((prev) => !prev);
    const data = {...announcement, isActive: !isActive};
    await dispatch(updatedAnnouncement(data, announcement.id));
    setIsLoading(false);
  };

  const handleDragStart = (e, id) => e.dataTransfer.setData('text/plain', id);

  const handleDragOver = (e) => e.preventDefault();

  const handleDrop = (e, targetId) => {
    const sourceId = e.dataTransfer.getData('text/plain');
    const draggedRow = announcements.find((row) => row.id === sourceId);
    const remainingRows = announcements.filter((row) => row.id !== sourceId);
    const targetIndex = remainingRows.findIndex((row) => row.id === targetId);

    const updatedRows = [
      ...remainingRows.slice(0, targetIndex),
      draggedRow,
      ...remainingRows.slice(targetIndex),
    ].map((row) => row.id);

    dispatch(updateAnnouncementsOrder(updatedRows));
  };

  return (
    <tr
      className="hover-bg-secondary-1 announcements-table-item"
      draggable
      onDragStart={(e) => handleDragStart(e, announcement.id)}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e, announcement.id)}
    >
      <td className="text-black announcement_table_item_image" width="150">
        {announcement.image && (
          <img src={announcement.image} alt="announcement" />
        )}
      </td>
      <td className="text-black" width="500">
        <small className="truncate-announcement">
          <PlainTextConverter htmlString={announcement.text} />
        </small>
      </td>
      <td className="text-black">
        <b style={{fontSize: '13px'}}>{announcement.date.split('T')[0]}</b>
      </td>
      <td>
        <div className=" form-switch d-flex align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            name="isActive"
            onChange={handleActiveChangeStatus}
            checked={isActive}
            disabled={isLoading}
          />
          <label className="form-check-label mb-0">Active</label>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-end">
          <Link
            className="btn btn-secondary shadow me-2 px-3"
            title="Edit Product"
            onClick={handleEdit}
          >
            <Edit /> &nbsp; <small>Edit</small>
          </Link>
          <Link
            className="btn btn-danger shadow px-3"
            title="Delete Product"
            onClick={handleDelete}
          >
            <Delete /> &nbsp; <small>Delete</small>
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default AnnouncementTableItem;
