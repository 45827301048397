import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import AnnouncementTableItem from './AnnouncementTableItem';

import Add from '../../../../icons/Add';

const AnnouncementsTable = ({openModal, openDeleteModal}) => {
  const announcements = useSelector((state) => state.announcements);

  let allAnnouncements = 0,
    activeAnnouncements = 0;

  const announcementsList = announcements.list.map((announcement) => {
    allAnnouncements++;
    if (announcement.isActive) activeAnnouncements++;

    return (
      <AnnouncementTableItem
        key={announcement.id}
        announcement={announcement}
        openModal={openModal}
        openDeleteModal={openDeleteModal}
      />
    );
  });

  const noAnnouncements = (
    <h4 className="text-center">No Announcements. Try adding some</h4>
  );

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header bg-secondary gradient-dark">
          <div>
            <h1 className="display-6 text-white">
              Announcements <br />
            </h1>
            <h5 className="text-white">
              All - {allAnnouncements} &nbsp; Active - {activeAnnouncements}
            </h5>
          </div>
          <Link className="btn btn-light shadow" onClick={openModal}>
            <Add /> &nbsp; Create New
          </Link>
        </div>
        <div className="card-body">
          {announcements.list.length > 0 ? (
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable ">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>{announcementsList}</tbody>
                </table>
              </div>
            </div>
          ) : (
            noAnnouncements
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsTable;
