import React, {useContext, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Popover, OverlayTrigger} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
// import defaultImage from '../../images/default-logo.jpg';
import {
  clearFilterAction,
  addItemAction,
  removeItemAction,
  searchItemAction,
  inputQuantityAction,
  resetCartAction,
  removeCurrenItem,
} from '../../store/actions/ShopActions';
import {
  orderBooleanAction,
  responseErrorAction,
} from '../../store/actions/OrderActions';
import {
  getCartItems,
  getExactTotalCartPrice,
  getTotalCartPrice,
  getTotalIncludedItems,
} from '../../store/selectors/ShopSelectors';
import {applyCouponAction} from '../../store/actions/CouponActions';
//Import Components
import {ThemeContext} from '../../context/ThemeContext';
import CategorySlider from '../components/Shop/CategorySlider';

import logo from '../../images/logo.png';
import {Form, Formik, Field} from 'formik';
import {postOrdersAction} from '../../store/actions/OrderActions';
import swal from 'sweetalert';
import {sortByCategory} from '../../utils/products';
import AnnouncementModals from '../components/Shop/AnnouncementModals';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const orderDetailSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const Home = () => {
  const dispatch = useDispatch();

  const uncategorizedItems = useSelector((state) => state.shop.searchedItems);
  const searchedItems = sortByCategory(uncategorizedItems);
  const allCategories = useSelector((state) => state.categories.categories);
  const searchedCategory = useSelector((state) => state.shop.searchedCategory);
  const orderResponse = useSelector((state) => state.orders.booleanAction);
  const isAuthenticated = useSelector((state) => state.auth.auth.idToken);
  const responseError = useSelector((state) => state.orders.responseError);
  const couponError = useSelector((state) => state.coupons.responseError);
  const appliedCoupon = useSelector((state) => state.coupons.appliedCoupon);
  const cartItemsUnCategorized = useSelector(getCartItems);
  const cartItems = sortByCategory(cartItemsUnCategorized);
  const totalCost = useSelector(getTotalCartPrice);
  const totalIncludedItems = useSelector(getTotalIncludedItems);
  const exactTotalPrice = useSelector(getExactTotalCartPrice);

  // const [searchedQuery, setSearchQuery] = useState('');
  const {changeBackground} = useContext(ThemeContext);
  const [notesModal, setNotesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');

  const resultsRef = useRef(null);
  const cartRef = useRef(null);
  const executeScroll = (refr) => scrollToRef(refr);

  useEffect(() => {
    changeBackground({value: 'light', label: 'Light'});
  }, []);
  useEffect(() => {
    if (responseError?.success == false) {
      swal(
        `Error ${
          responseError?.err?.statusCode ? responseError.err.statusCode : ''
        }`,
        `${responseError?.err?.message ? responseError.err.message : ''}`,
        'error'
      );
      dispatch(responseErrorAction({}));
      setIsLoading(false);
    }
    if (orderResponse) {
      dispatch(orderBooleanAction(false));
      swal('Order Sent!', 'Thank You!', 'success');
      dispatch(resetCartAction());
      setNotes('');
      setIsLoading(false);
    }
  }, [orderResponse, responseError]);

  const searchHandle = (query) => {
    if (query !== '' && query.length >= 1) {
      if (query.includes('restricted')) {
        query = '*';
      } else if (query.includes('walden farms')) {
        query = 'wf';
      }
      dispatch(searchItemAction(query));
    } else {
      dispatch(searchItemAction(''));
    }
  };
  const searchButtonHandle = (e) => {
    const {search} = e.target;
    if (search.value !== '') {
      searchHandle(search.value);
      executeScroll(resultsRef);
    }
  };

  const handleChange = (itemId, itemValue) => {
    dispatch(inputQuantityAction(itemId, itemValue));
  };

  const AddNote = (e) => {
    e.preventDefault();
    const {note} = e.target;
    setNotes(note.value);
    setNotesModal(false);
  };

  const usaDateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <>
      <AnnouncementModals />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="row">
            {isAuthenticated ? (
              <Link to="/dashboard" className="mb-2 ">
                Go to Dashboard{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  height={'15px'}
                  width={'25px'}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </Link>
            ) : (
              ''
            )}
            <div className="card blance-card gradient-15 p-3">
              <div className="card-body">
                <div className="d-md-flex">
                  <div className="me-3">
                    <img
                      src={logo}
                      className="position-relative"
                      style={{zIndex: '9'}}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h1 className="mb-0 text-white font-fishing">
                      The Ideal Weigh
                    </h1>
                    <h3 className="text-white font-fishing">
                      Pre-Order Food for The Ideal Weigh of Mt. Juliet, TN
                    </h3>
                    <p className="mb-0 text-white">
                      Please place your order a minimum of 24 hours (48 hours
                      would be even better) before your next appointment. Don't
                      worry, you won't be charged until after your appointment
                      so you will be able to add in any last minute items at
                      your visit. You will need 21 IP items for the week: seven
                      breakfasts, seven lunches, seven snacks with maximum of
                      seven restricted items.<br></br>Everything is ordered
                      individually, for example if you order three of an item
                      that is three packets, if you would like a box then please
                      order seven. The only exception is RTS drinks and puddings
                      are in quantities of six for a full sleeve.<br></br>An *
                      means the item is restricted.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <label className="" htmlFor="search">
                <h4 className=" mb-0 cate-title">Search</h4>
              </label>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  searchButtonHandle(e);
                }}
              >
                <div className="input-group mb-2" style={{maxWidth: '450px'}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Product name"
                    name="search"
                    id="search"
                    onChange={(e) => searchHandle(e.target.value)}
                  />
                  <input
                    href="#results"
                    className="btn btn-sm gradient-15 text-white"
                    type="submit"
                    value="Search"
                  />
                </div>
              </form>
              <div className="mt-3">
                <h4 className="cate-title me-3 d-inline-block">Filter:</h4>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(clearFilterAction());
                    executeScroll(resultsRef);
                  }}
                  className="btn btn-secondary btn-lg py-2 px-4 rounded-pill"
                >
                  All Items
                </button>{' '}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    searchHandle('*');
                    executeScroll(resultsRef);
                  }}
                  className="btn btn-secondary btn-lg py-2 px-4 me-1 rounded-pill"
                >
                  Restricted
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    searchHandle('wf');
                    executeScroll(resultsRef);
                  }}
                  className="btn btn-secondary btn-lg py-2 px-4 rounded-pill"
                >
                  Walden Farms
                </button>
              </div>
            </div>
            <div className="col-xl-12 mb-5">
              <CategorySlider />
            </div>
            <div id="results" ref={resultsRef} className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className=" mb-0 cate-title">
                  {searchedCategory ? (
                    <>
                      <span>
                        Results:{' '}
                        {searchedCategory?.title == '*'
                          ? 'Restricted'
                          : searchedCategory?.title == 'wf'
                          ? 'Walden Farms'
                          : searchedCategory.title}
                      </span>{' '}
                      &nbsp;
                      <small>
                        <button
                          // href=""
                          className="bg-warning p-1 px-3 border border-primary rounded btn-sm text-white"
                          onClick={(e) => dispatch(clearFilterAction())}
                        >
                          <small>X Clear Filters</small>
                        </button>
                      </small>
                    </>
                  ) : (
                    'All Items'
                  )}
                </h4>
              </div>
              <div className="row">
                {searchedItems.length > 0 ? (
                  searchedItems.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-3">
                        <div className="card dishe-bx b-hover review style-1">
                          <div className="card-body text-center py-3 d-flex justify-content-center">
                            <img
                              src={item?.image || 'default-logo.jpg'}
                              alt={item.title}
                              style={{objectFit: 'cover'}}
                            />
                          </div>
                          <div className="card-footer pt-0 border-0 text-center">
                            <div>
                              <h3 className="mb-2 font-fishing">
                                {item.title}
                              </h3>
                            </div>
                            <div style={{maxWidth: '200px', margin: '0 auto'}}>
                              {item.available ? (
                                <>
                                  <div className="input-group border border-secondary rounded">
                                    <button
                                      className="btn bg-light hover-bg-secondary-2 text-dark"
                                      type="button"
                                      data-decrease
                                      onClick={() =>
                                        dispatch(removeItemAction(item.id))
                                      }
                                    >
                                      —
                                    </button>
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      value={item.number}
                                      style={{border: 'none'}}
                                      onChange={(e) => {
                                        handleChange(item.id, e.target.value);
                                      }}
                                    />
                                    <button
                                      className="btn bg-secondary-7 hover-bg-secondary text-white"
                                      type="button"
                                      data-increase
                                      onClick={() => {
                                        dispatch(addItemAction(item.id));
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="top"
                                    overlay={
                                      <Popover
                                        id="popover-positioned-top"
                                        title="Popover top"
                                        style={{
                                          maxWidth: '250px',
                                          backgroundColor: '#9d1cb2',
                                          color: '#ffffff',
                                          border: 'none',
                                          borderRadius: '10px',
                                          fontSize: '12px',
                                          display:
                                            item?.notes || item?.willBeInStockOn
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <div style={{padding: '10px'}}>
                                          {item?.willBeInStockOn && (
                                            <div>
                                              <b>Expected return date :</b>
                                              &nbsp;
                                              {new Date(
                                                item?.willBeInStockOn
                                              ).toLocaleDateString(
                                                'en-US',
                                                usaDateOptions
                                              )}
                                            </div>
                                          )}
                                          {item?.notes && (
                                            <div>
                                              <b>Notes :</b>
                                              &nbsp;
                                              {item?.notes}
                                            </div>
                                          )}
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <div
                                      className="position-relative out-of-stock-bg text-dark px-3 py-1 rounded d-flex align-items-center justify-content-center"
                                      style={{
                                        height: '50px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Out of stock
                                      <div
                                        style={{
                                          position: 'absolute',
                                          right: 10,
                                          cursor: 'pointer',
                                          display:
                                            item?.notes || item?.willBeInStockOn
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <i class="bi bi-question-circle-fill"></i>
                                      </div>
                                    </div>
                                  </OverlayTrigger>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h4 className="text-dark pt-3">No Result found</h4>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-xxl-12">
          <div className="row mt-5" ref={cartRef}>
            <div className="col-md-8 mb-3">
              <h4 className="cate-title">
                Your Cart{' '}
                {cartItems.length > 0 ? (
                  <small>
                    <button
                      className="bg-warning p-1 px-3 border border-primary rounded btn-sm text-white"
                      onClick={(e) => dispatch(resetCartAction())}
                    >
                      <small>X Clear Cart</small>
                    </button>
                  </small>
                ) : (
                  ''
                )}
              </h4>

              {cartItems.length > 0 ? (
                cartItems.map((item, index) => (
                  <div
                    className=" shadow order-check hover-bg-secondary-1 d-flex align-items-center p-3 my-3 mb-4 rounded-0 position-relative"
                    key={index}
                    style={{transition: 'all ease 0.4s'}}
                  >
                    <button
                      className="bg-warning shadow rounded-circle text-warning p-0 border-0 btn-sm position-absolute top-0 start-100 translate-middle"
                      onClick={(e) => dispatch(removeCurrenItem(item.id))}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#fff"
                        className="w-6 h-6"
                        height="26px"
                        width="26px"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                    <div className="dlab-media border-secondary shadow">
                      <img
                        src={item.image || '/default-logo.jpg'}
                        alt={item.title}
                        style={{objectFit: 'cover'}}
                      />
                    </div>
                    <div className="dlab-info d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="dlab-title mb-0 font-fishing">
                          {item.title}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="quntity border-secondary">
                          <button
                            data-decrease
                            onClick={() => dispatch(removeItemAction(item.id))}
                            className="bg-light text-primary border-secondary"
                          >
                            -
                          </button>
                          <input
                            data-value
                            type="text"
                            value={item.number}
                            onChange={(e) =>
                              handleChange(item.id, e.target.value)
                            }
                          />
                          <button
                            data-increase
                            onClick={() => dispatch(addItemAction(item.id))}
                            className="bg-secondary text-white"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-5 mt-3 bg-light">No Items in Cart</div>
              )}
            </div>
            <div className="col-md-4">
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                }}
                validationSchema={orderDetailSchema}
                onSubmit={(values) => {
                  const orderData = {};
                  for (const [key, value] of Object.entries(values)) {
                    orderData[key] = value;
                  }

                  const cartItemsArray = [];
                  for (let i = 0; i < cartItems.length; i++) {
                    const singleItem = {};
                    for (const [key, value] of Object.entries(cartItems[i])) {
                      if (key === 'available' || key === 'isInclude') {
                      } else {
                        if (key === 'categoryId') {
                          let itemCategory = allCategories.filter((catItem) => {
                            return catItem.id == value.toString();
                          });
                          if (itemCategory.length) {
                            let categoryObject = itemCategory[0];
                            delete categoryObject.titleLower;
                            delete categoryObject.image;
                            singleItem['category'] = categoryObject;
                          }
                        } else {
                          singleItem[key] = value;
                        }
                      }
                    }
                    cartItemsArray.push(singleItem);
                  }

                  orderData['message'] = notes;
                  orderData['items'] = cartItemsArray;
                  orderData['total'] = exactTotalPrice;
                  orderData['price'] = totalCost;
                  orderData['totalItems'] = totalIncludedItems;
                  if (appliedCoupon) {
                    orderData['coupon'] = appliedCoupon;
                  }
                  dispatch(postOrdersAction(orderData));
                  setIsLoading(true);
                }}
              >
                {({errors, touched, resetForm}) => (
                  <Form>
                    <div className="card dlab-bg dlab-position overflow-hidden border-secondary bg-secondary-1">
                      <div className="card-header border-0 pb-0">
                        <h4 className="cate-title">Total</h4>
                      </div>
                      <div className="card-body pt-0 pb-2">
                        <div className="bb-border mb-3">
                          <hr
                            className="mt-2 mb-8 text-secondary"
                            style={{opacity: '0.9', marginBottom: '30px'}}
                          />
                          <div className="row g-3 mb-3">
                            <div className="col">
                              <label className="text-black">Your Name</label>
                              <Field
                                type="text"
                                name="name"
                                className="form-control form-control-sm"
                                required
                                placeholder="John Doe."
                              />
                              {touched.name && errors.name && (
                                <div className="text-danger">{errors.name}</div>
                              )}
                            </div>
                            <div className="col">
                              <label className="text-black">
                                Email Address
                              </label>
                              <Field
                                type="text"
                                name="email"
                                className="form-control form-control-sm"
                                required
                                placeholder="your@email.com"
                              />
                              {touched.email && errors.email && (
                                <div className="text-danger">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h5 className="mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="var(--secondary)"
                                className="cc me-2"
                                width="24"
                                height="24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                />
                              </svg>
                              Extra Note
                            </h5>
                            <Link
                              to={'#'}
                              className="btn btn-secondary btn-sm change"
                              onClick={() => setNotesModal(true)}
                            >
                              Add Note
                            </Link>
                          </div>
                          <p style={{whiteSpace: 'pre-line'}}>{notes}</p>
                        </div>
                        <ul className="mb-3">
                          {cartItems.map((item, index) => (
                            <li key={index}>
                              <span className="text-black">
                                {item.title}{' '}
                                <b className="p-1 px-2 bg-secondary-2 text-dark rounded">
                                  x {item.number}
                                </b>
                              </span>
                              <hr
                                className="text-secondary"
                                style={{opacity: '0.4'}}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mb-3 dlab-bg dlab-position overflow-hidden bg-secondary-2">
                        <div className="card-header border-0 pb-0">
                          <h5 className="">Have a Coupon Code?</h5>
                        </div>
                        <div className="card-body pt-0 pb-3">
                          <form>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Coupon Code"
                                aria-label="Coupon Code"
                                aria-describedby="couponBtn"
                                name="couponCode"
                              />
                              <button
                                className="btn btn-secondary btn-sm"
                                type="submit"
                                id="couponBtn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // console.log(e.target.form.couponCode.value);
                                  const couponCode =
                                    e.target.form.couponCode.value;
                                  if (couponCode !== '') {
                                    dispatch(applyCouponAction(couponCode));
                                  }
                                }}
                              >
                                Apply
                              </button>
                            </div>
                            {couponError?.success == false ? (
                              <span className="text-danger">
                                Invalid Coupon!
                              </span>
                            ) : (
                              ''
                            )}
                            {appliedCoupon ? (
                              <span className="text-secondary">
                                <i className="bi bi-check"></i> Coupon Applied
                              </span>
                            ) : (
                              ''
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="card-footer mt-3 pt-0 border-0">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="mb-0 font-w500">
                            Total IP Food Items
                          </h6>
                          <p className="mb-0 font-w500 text-secondary">
                            {totalIncludedItems}
                          </p>
                        </div>
                        {appliedCoupon ? (
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h6 className="mb-0 font-w500">Total</h6>
                            <p className="mb-0 font-w500 text-secondary">
                              ${exactTotalPrice}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {appliedCoupon ? (
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h6 className="mb-0 font-w500">Coupon</h6>
                            <p className="mb-0 font-w500 text-secondary">
                              {appliedCoupon.type == 'fixed'
                                ? `-$${appliedCoupon.price}`
                                : `-${appliedCoupon.price}%`}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        <hr></hr>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h4 className="font-w500">Total Amount</h4>
                          <h3 className="font-w500 text-secondary">
                            ${totalCost && totalCost > 0 ? totalCost : '0.00'}
                          </h3>
                        </div>
                        <button
                          // to="/checkout"
                          className="btn btn-secondary shadow btn-block"
                          type="submit"
                          disabled={cartItems.length > 0 ? false : true}
                        >
                          {!isLoading ? (
                            'Checkout'
                          ) : (
                            <div
                              className="spinner-grow text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-xl-12"></div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        id="exampleModal2"
        show={notesModal}
        onHide={setNotesModal}
      >
        <div className="modal-header bg-secondary">
          <h5 className="modal-title text-white" id="exampleModalLabel2">
            Manage Extra Notes
          </h5>
          <button
            type="button"
            className="btn-close text-white"
            onClick={() => setNotesModal(false)}
          ></button>
        </div>
        <form method="post" onSubmit={AddNote}>
          <div className="modal-body add-note">
            <div className="row align-items-center">
              <div className="col-xl-12">
                <div className="mb-3">
                  <label className="form-label">Note for your order</label>
                  <textarea
                    className="form-control p-3"
                    placeholder="Type Message Here..."
                    id="floatingTextarea"
                    style={{height: '150px'}}
                    name="note"
                    defaultValue={notes}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setNotesModal(false)}
            >
              Close
            </button>
            <input
              type="submit"
              className="btn btn-secondary"
              value="Save Changes"
            />
          </div>
        </form>
      </Modal>
      {cartItems.length > 0 ? (
        <button
          className="rounded-0 border-0 bg-secondary shadow p-2 px-2 position-fixed top-50 start-0 rounded-end"
          onClick={(e) => {
            e.preventDefault();
            executeScroll(cartRef);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#ffffff"
            height="25px"
            width="25px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
          <span
            className="position-absolute bg-danger rounded-circle text-white top-0 start-100 translate-middle shadow"
            style={{height: '15px', width: '15px', fontSize: '10px'}}
          >
            {totalIncludedItems > 9 ? (
              <small>{totalIncludedItems}</small>
            ) : (
              totalIncludedItems
            )}
          </span>
        </button>
      ) : (
        ''
      )}
    </>
  );
};
export default Home;
