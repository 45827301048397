import axios from '../utils/axios';

export async function getCoupons() {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const coupons = await axios({
    method: 'get',
    url: '/coupons',
    headers,
  }).then(function (response) {
    return response;
  });
  return coupons;
}

export async function getCouponViaTitle(title) {
  const coupon = await axios({
    method: 'get',
    url: `/coupons/${title}`,
  }).then(function (response) {
    return response;
  });
  return coupon;
}

export async function createCoupon(couponData) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const singleCoupon = await axios
    .post('/coupons', couponData, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        err,
      };
    });
  return singleCoupon;
}

export async function updateCoupon(coupon, couponId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const updatedCoupon = await axios
    .patch(`/coupons/${couponId}`, coupon, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch(function (err) {
      return {
        success: false,
        err,
      };
    });

  return updatedCoupon;
}

export async function deleteCoupon(couponId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  var config = {
    method: 'delete',
    url: `/coupons/${couponId}`,
    headers,
  };
  const deletedCoupon = await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  return deletedCoupon;
}

// export function formatCategories(postsData) {
//   let posts = [];
//   for (let key in postsData) {
//     posts.push({...postsData[key], id: key});
//   }

//   return posts;
// }
