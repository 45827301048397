export const orderAnnouncements = (order, announcements) => {
  const orderedAnnouncements = [];

  order.forEach((announcementId) => {
    const announcement = announcements.find(
      (announcement) => announcementId === announcement.id
    );
    if (announcement && announcement.isActive)
      orderedAnnouncements.push(announcement);
  });

  return orderedAnnouncements;
};
