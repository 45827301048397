import axios from '../utils/axios';

export async function getCategories() {
  const categories = await axios({
    method: 'get',
    url: '/categories',
  }).then(function (response) {
    return response;
  });
  return categories;
}

export async function createCategory(categoryData) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const singleCategory = await axios
    .post('/categories', categoryData, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        err,
      };
    });
  return singleCategory;
}

export async function updateCategory(category, categoryId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails?.idToken;
  const updatedCategory = await axios
    .patch(`/categories/${categoryId}`, category, {
      headers,
    })
    .then(function (response) {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch(function (err) {
      return {
        success: false,
        err,
      };
    });

  return updatedCategory;
}

export async function deleteCategory(categoryId) {
  const headers = {};
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  headers['x-auth-token'] = userDetails.idToken;
  var config = {
    method: 'delete',
    url: `/categories/${categoryId}`,
    headers,
  };
  const deletedCategory = await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  return deletedCategory;
}

export function formatCategories(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({...postsData[key], id: key});
  }

  return posts;
}
