import {
  createCoupon,
  formatCategories,
  getCoupons,
  deleteCoupon,
  updateCoupon,
  getCouponViaTitle,
} from '../../services/CouponService';
import {
  CONFIRMED_CREATE_CATEGORY_ACTION,
  CONFIRMED_DELETE_CATEGORY_ACTION,
  CONFIRMED_EDIT_CATEGORY_ACTION,
  CONFIRMED_GET_CATEGORIES,
} from './CategoryTypes';

export function deleteCouponAction(couponId, history) {
  return (dispatch, getState) => {
    deleteCoupon(couponId).then((response) => {
      dispatch(confirmedDeleteCouponAction(couponId));
    });
  };
}

export function confirmedDeleteCouponAction(couponId) {
  return {
    type: 'CONFIRMED_DELETE_COUPON_ACTION',
    payload: couponId,
  };
}

export function createCouponAction(couponData, history) {
  return (dispatch, getState) => {
    createCoupon(couponData).then((response) => {
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedCreateCouponAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
export function responseErrorAction(error) {
  return {
    type: 'ERROR_OCCURD',
    payload: error,
  };
}
export function closeModel() {
  return {
    type: 'CLOSE_MODEL',
  };
}
export function getCouponsAction() {
  return (dispatch, getState) => {
    getCoupons().then((response) => {
      let coupons = response.data;
      dispatch(confirmedGetCouponsAction(coupons));
    });
  };
}

export function getCouponViaTitleAction(title) {
  return (dispatch, getState) => {
    getCouponViaTitle(title).then((response) => {
      let coupon = response.data;
      dispatch(confirmedGetCouponsAction(coupon));
    });
  };
}

export function confirmedCreateCouponAction(singleCoupon) {
  return {
    type: 'CONFIRMED_CREATE_COUPON_ACTION',
    payload: singleCoupon,
  };
}

export function confirmedGetCouponsAction(coupons) {
  return {
    type: 'CONFIRMED_GET_COUPONS',
    payload: coupons,
  };
}

export function confirmedUpdateCouponAction(coupon) {
  return {
    type: 'CONFIRMED_EDIT_COUPON_ACTION',
    payload: coupon,
  };
}

export function updateCouponAction(coupon, couponId, history) {
  return (dispatch, getState) => {
    updateCoupon(coupon, couponId).then((response) => {
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedUpdateCouponAction(data));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
export function applyCouponAction(couponCode) {
  return (dispatch) => {
    getCouponViaTitle(couponCode).then((response) => {
      let coupon = response.data;
      dispatch(confirmedApplyCouponAction(coupon));
    });
  };
}
export function confirmedApplyCouponAction(couponCode) {
  return {
    type: 'CONFIRMED_APPLY_COUPON_ACTION',
    payload: couponCode,
  };
}
