import React, {useRef, useState, useEffect} from 'react';

import {Editor} from '@tinymce/tinymce-react';
import {Modal} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

const AddEditModal = ({show, onHide, onSubmit, loading}) => {
  const {selectedAnnouncement} = useSelector((state) => state.announcements);
  const isEditMode = !isEmpty(selectedAnnouncement);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const editorRef = useRef(null);

  const handleSubmit = async () => {
    if (!editorRef.current) return;

    await onSubmit({text: editorRef.current.getContent(), image});
    setImage(null);
  };

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const closeModal = () => {
    setImage(null);
    setImagePreview(null);
    onHide();
  };

  useEffect(() => {
    const previewUrl = isEditMode ? selectedAnnouncement.image : '';
    setImagePreview(previewUrl);
  }, [isEditMode, selectedAnnouncement]);

  useEffect(() => {
    const previewUrl = image ? URL.createObjectURL(image) : '';
    setImagePreview(previewUrl);
  }, [image]);

  return (
    <Modal className="modal fade" size="lg" show={show} onHide={closeModal}>
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">New Announcement</h4>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={closeModal}
            >
              <span></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="announcement-image-selector">
                  {imagePreview && (
                    <img src={imagePreview} alt="announcement" />
                  )}
                  <input
                    id="announcementImage"
                    type="file"
                    className="form-control"
                    autoComplete="off"
                    name="imageHolder"
                    onChange={handleImageUpload}
                  />
                </div>
                <div className="form-group mb-3">
                  <Editor
                    onInit={(_, editor) => (editorRef.current = editor)}
                    initialValue={selectedAnnouncement.text}
                    init={{height: 300}}
                    apiKey={'xkx4wnxl22x4rhk2ik17pyyaghiy6uvqfm53lh3f64a9cea0'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              style={{overflow: 'hidden', position: 'relative'}}
              onClick={handleSubmit}
              disabled={loading}
            >
              {isEditMode ? 'Update' : 'Add'}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeModal}
              disabled={loading}
            >
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditModal;
