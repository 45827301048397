import {getOrders, postOrder} from '../../services/OrderService';
export function getOrdersAction() {
  return (dispatch, getState) => {
    getOrders().then((response) => {
      let orders = response.data;
      dispatch(confirmedGetOrdersAction(orders));
    });
  };
}
export function confirmedGetOrdersAction(orders) {
  return {
    type: 'GET_ORDERS_ACTION',
    payload: orders,
  };
}
export function viewOrderAction(order) {
  return {
    type: 'VIEW_ORDER_ACTION',
    payload: order,
  };
}
export function searchItemAction(query) {
  return {
    type: 'SEARCH_ITEM_ACTION',
    payload: query,
  };
}
export function postOrdersAction(orderData) {
  return (dispatch, getState) => {
    postOrder(orderData).then((response) => {
      // let orders = response.data;
      // dispatch(confirmedPostOrdersAction(orders));
      // dispatch(orderBooleanAction(true));
      const {success, data, err} = response;
      if (success) {
        dispatch(confirmedPostOrdersAction(data));
        dispatch(orderBooleanAction(true));
      } else {
        dispatch(
          responseErrorAction({
            success: false,
            err,
          })
        );
      }
    });
  };
}
export function responseErrorAction(error) {
  return {
    type: 'ERROR_OCCURD',
    payload: error,
  };
}
export function confirmedPostOrdersAction(orders) {
  return {
    type: 'POST_ORDER_ACTION',
    payload: orders,
  };
}
export function orderBooleanAction(boolean) {
  return {
    type: 'BOOLEAN_ACTION',
    payload: boolean,
  };
}
