export const sortByCategory = (items) => {
  const sortedItems = [];
  const categoryMap = new Map();

  // Group items by category
  items.forEach((item) => {
    const category = item.categoryId[0];

    const catExist = categoryMap.has(category);
    if (!catExist) categoryMap.set(category, []);

    categoryMap.get(category).push(item);
  });

  // Sort items within each category
  categoryMap.forEach((categoryItems) => sortedItems.push(...categoryItems)); // categoryItems is an array of items of the same category

  return sortedItems;
};
