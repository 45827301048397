import React, {useContext, useEffect, useReducer, useState} from 'react';
import ProductsTable from './Products/ProductsTable';
import {ThemeContext} from '../../../context/ThemeContext';

export default function EditProducts() {
  const {changeBackground} = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({value: 'light', label: 'Light'});
  }, []);
  return (
    <section className="container-fluid">
      <div className="conatiner">
        <div className="row">
          <ProductsTable />
        </div>
      </div>
    </section>
  );
}
