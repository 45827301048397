const initialState = {
  allProducts: [],
  searchedItems: [],
  searchedCategory: null,
};

export default function ShopReducer(state = initialState, actions) {
  if (actions.type === 'LOAD_PRODUCTS') {
    return {
      ...state,
      allProducts: actions.payload,
      searchedItems: actions.payload,
    };
  }
  if (actions.type === 'CLEAR_ALL_FILTER_ACTION') {
    return {
      ...state,
      searchedCategory: null,
      searchedItems: state.allProducts,
    };
  }
  if (actions.type === 'CATEGORY_SEARCH_ACTION') {
    const filteredData = state.allProducts.filter((item) => {
      return item.categoryId.includes(actions.payload.id);
    });
    const queryType = actions.payload;
    queryType['queryType'] = 'category';
    return {
      ...state,
      searchedCategory: queryType,
      searchedItems: filteredData,
    };
  }
  if (actions.type === 'ADD_ITEM_ACTION') {
    const productId = actions.payload;
    let allProducts = state.allProducts.map((data) => {
      if (productId === data.id) {
        return {...data, number: data.number + 1};
      }
      return data;
    });

    let searchedItems = state.searchedItems.map((data) => {
      if (productId === data.id) {
        return {...data, number: data.number + 1};
      }
      return data;
    });
    return {
      ...state,
      allProducts,
      searchedItems,
    };
  }
  if (actions.type === 'REMOVE_ITEM_ACTION') {
    const productId = actions.payload;
    let allProducts = state.allProducts.map((data) => {
      if (productId === data.id) {
        return {
          ...data,
          number: data.number > 0 ? data.number - 1 : data.number,
        };
      }
      return data;
    });

    let searchedItems = state.searchedItems.map((data) => {
      if (productId === data.id) {
        return {
          ...data,
          number: data.number > 0 ? data.number - 1 : data.number,
        };
      }
      return data;
    });
    return {
      ...state,
      allProducts,
      searchedItems,
    };
  }
  if (actions.type === 'INPUT_QUANTITY_ACTION') {
    const productId = actions.payload.id;
    const itemValue = actions.payload.qty;
    let allProducts = state.allProducts.map((data) => {
      if (productId === data.id) {
        let val = itemValue && itemValue >= 0 ? itemValue : 0;
        return {
          ...data,
          number: parseInt(val),
        };
      }
      return data;
    });
    let searchedItems = state.searchedItems.map((data) => {
      if (productId === data.id) {
        let val = itemValue && itemValue >= 0 ? itemValue : 0;
        return {
          ...data,
          number: parseInt(val),
        };
      }
      return data;
    });
    return {
      ...state,
      allProducts,
      searchedItems,
    };
  }
  if (actions.type === 'SEARCH_ITEM_ACTION') {
    if (actions.payload !== '') {
      let searchedItems = state.allProducts.filter((item) => {
        let title = item.title.toLowerCase();
        let searchedQuery = actions.payload.toLowerCase();
        return title.includes(searchedQuery);
      });
      return {
        ...state,
        searchedCategory: {queryType: 'search', title: actions.payload},
        searchedItems,
      };
    } else {
      return {
        ...state,
        searchedCategory: null,
        searchedItems: state.allProducts,
      };
    }
  }
  if (actions.type == 'RESET_CART_ACTION') {
    const productsArr = state.allProducts;
    const updatedProducts = productsArr.map((item, index) => {
      item['number'] = 0;
      return item;
    });
    return {
      ...state,
      allProducts: updatedProducts,
      searchedItems: updatedProducts,
    };
  }
  if (actions.type == 'REMOVE_CURRENT_ITEM_ACTION') {
    let allProducts = state.allProducts.map((data) => {
      if (actions.payload === data.id) {
        return {
          ...data,
          number: 0,
        };
      }
      return data;
    });
    let searchedItems = state.searchedItems.map((data) => {
      if (actions.payload === data.id) {
        return {
          ...data,
          number: 0,
        };
      }
      return data;
    });
    return {
      ...state,
      allProducts,
      searchedItems,
    };
  }
  // Return this state
  return state;
}
