import {
  CONFIRMED_CREATE_CATEGORY_ACTION,
  CONFIRMED_DELETE_CATEGORY_ACTION,
  CONFIRMED_EDIT_CATEGORY_ACTION,
  CONFIRMED_GET_CATEGORIES,
  // CREATE_CATEGORY_ACTION,
} from '../actions/CategoryTypes';

const initialState = {
  categories: [],
  responseError: {},
  modelState: false,
};

export default function CategoriesReducer(state = initialState, actions) {
  if (actions.type === CONFIRMED_DELETE_CATEGORY_ACTION) {
    const categories = [...state.categories];
    const categoryIndex = categories.findIndex(
      (category) => category.id === actions.payload
    );

    categories.splice(categoryIndex, 1);

    return {
      ...state,
      categories,
    };
  }

  if (actions.type === CONFIRMED_EDIT_CATEGORY_ACTION) {
    const categories = [...state.categories];
    const categoryIndex = categories.findIndex(
      (category) => category.id === actions.payload.id
    );

    categories[categoryIndex] = actions.payload;
    return {
      ...state,
      categories,
      modelState: true,
    };
  }

  if (actions.type === CONFIRMED_CREATE_CATEGORY_ACTION) {
    const categories = [...state.categories];
    categories.push(actions.payload);

    return {
      ...state,
      categories,
      modelState: true,
    };
  }
  if (actions.type == 'ERROR_OCCURD') {
    return {
      ...state,
      responseError: actions.payload,
    };
  }
  if (actions.type == 'CLOSE_MODEL') {
    return {
      ...state,
      modelState: false,
    };
  }
  if (actions.type === CONFIRMED_GET_CATEGORIES) {
    return {
      ...state,
      categories: actions.payload,
    };
  }
  return state;
}
