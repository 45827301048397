const initialState = {
  orders: [],
  searchedOrders: [],
  searchQuery: null,
  booleanAction: false,
  viewOrder: null,
  responseError: {},
};

export default function OrdersReducer(state = initialState, actions) {
  if (actions.type === 'GET_ORDERS_ACTION') {
    const orders = actions.payload;
    return {
      ...state,
      orders: orders.reverse(),
      searchedOrders: orders,
    };
  }
  if (actions.type === 'SEARCH_ITEM_ACTION') {
    if (actions.payload !== '') {
      let searchedOrders = state.orders.filter((item) => {
        let title = item.name.toLowerCase();
        let searchedQuery = actions.payload.toLowerCase();
        return title.includes(searchedQuery);
      });
      return {
        ...state,
        searchedOrders,
        searchQuery: actions.payload,
      };
    } else {
      return {
        ...state,
        searchedOrders: state.orders,
        searchQuery: null,
      };
    }
  }
  if (actions.type == 'ERROR_OCCURD') {
    return {
      ...state,
      responseError: actions.payload,
    };
  }
  if (actions.type === 'VIEW_ORDER_ACTION') {
    return {
      ...state,
      viewOrder: actions.payload,
    };
  }
  if (actions.type === 'POST_ORDER_ACTION') {
    const orders = [...state.orders];
    orders.push(actions.payload);
    return {
      ...state,
      orders,
    };
  }
  if (actions.type === 'BOOLEAN_ACTION') {
    return {
      ...state,
      booleanAction: actions.payload,
    };
  }

  return state;
}
