import React, {useContext, useState} from 'react';
/// React router dom
import {Link} from 'react-router-dom';
import {ThemeContext} from '../../../context/ThemeContext';
import Logo from '../../../images/logo.png';
export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector('#main-wrapper');
    if (mainwrapper.classList.contains('menu-toggle')) {
      mainwrapper.classList.remove('menu-toggle');
    } else {
      mainwrapper.classList.add('menu-toggle');
    }
  }, 200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const {navigationHader, openMenuToggle, background} =
    useContext(ThemeContext);
  return (
    <div className="nav-header gradient-dark">
      <Link to="/" className="brand-logo">
        <img src={Logo} height="70" />
        <span className="brand-title text-white">Ideal Weigh</span>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
