import React, {useContext, useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

const Dashboard = () => {
  const ordersCount = useSelector((state) => state.orders.orders);
  const productsCount = useSelector((state) => state.products.products);
  const categoriesCount = useSelector((state) => state.categories.categories);
  const couponsCount = useSelector((state) => state.coupons.coupons);
  const announcements = useSelector((state) => state.announcements.list);
  return (
    <>
      <div className="row">
        <h1>Dashboard</h1>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <Link to="/dashboard/orders">
            <div className="widget-stat card bg-danger gradient-17 overflow-hidden">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3 icon-bg">
                    <i className="bi bi-file-text" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1 title-c">Orders</p>
                    <h3 className="text-white count-c">{ordersCount.length}</h3>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <Link to="/dashboard/products">
            <div className="widget-stat card bg-success gradient-18 overflow-hidden">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3 icon-bg">
                    <i className="bi bi-shop" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1 title-c">Products</p>
                    <h3 className="text-white count-c">
                      {productsCount.length}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <Link to="/dashboard/categories">
            <div className="widget-stat card bg-info gradient-14 overflow-hidden">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3 icon-bg">
                    <i className="bi bi-pie-chart" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1 title-c">Categories</p>
                    <h3 className="text-white count-c">
                      {categoriesCount.length}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <Link to="/dashboard/coupons">
            <div className="widget-stat card bg-primary gradient-16 overflow-hidden">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3 icon-bg">
                    <i className="bi bi-upc-scan" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1 title-c">Coupons</p>
                    <h3 className="text-white count-c">
                      {couponsCount.length}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <Link to="/dashboard/announcements">
            <div className="widget-stat card bg-success gradient-19 overflow-hidden">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3 icon-bg">
                    <i className="bi bi-megaphone" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1 title-c">Announcements</p>
                    <h3 className="text-white count-c">
                      {announcements.length}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
