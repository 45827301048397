import React, {useEffect, useRef, useState} from 'react';

import {Modal} from 'react-bootstrap';

const AnnouncementModal = ({style, announcement, onAcknowledge, cancelAll}) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    onAcknowledge(announcement.id);
    setIsOpen(false);
  };

  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    ref.current.innerHTML = `${announcement.text}`;
  }, [announcement]);

  return (
    <Modal
      className="modal fade"
      size="lg"
      backdrop={false}
      centered
      show={isOpen}
      onHide={closeModal}
      style={style}
    >
      <div role="document">
        <div className="modal-header justify-content-center">
          <h4 className="modal-title fs-20 center"> -- Announcement --</h4>
        </div>
        {announcement.image && (
          <img
            className="announcement-modal-image"
            src={announcement.image}
            alt="announcement"
          />
        )}
        <div className="modal-body" ref={ref}></div>
        <div className="modal-footer justify-content-center">
          <button
            type="submit"
            className="btn btn-primary"
            style={{overflow: 'hidden', position: 'relative'}}
            onClick={closeModal}
          >
            Acknowledged
          </button>

          <button
            type="submit"
            className="btn btn-danger"
            style={{overflow: 'hidden', position: 'relative'}}
            onClick={cancelAll}
          >
            Dismiss All
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AnnouncementModal;
