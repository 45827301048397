import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {ToastContainer} from 'react-toastify';

import DeleteModal from './DeleteModal';
import AddEditModal from './AddEditModal';
import AnnouncementsTable from './AnnouncementsTable';

import {
  CLEAR_SELECTED_ANNOUNCEMENT,
  creatAnnouncement,
  deleteAnnouncement,
  updatedAnnouncement,
} from '../../../../store/actions/AnnouncementActions';

import './styles.css';

const Announcements = () => {
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const {selectedAnnouncement: selected} = useSelector(
    (state) => state.announcements
  );

  const dispatch = useDispatch();

  const openDeleteModal = () => setDeleteModal(true);
  const openModal = () => setAnnouncementModal(true);
  const closeModal = () => {
    dispatch({type: CLEAR_SELECTED_ANNOUNCEMENT});
    setAnnouncementModal(false);
  };
  const closeDeleteModal = () => {
    dispatch({type: CLEAR_SELECTED_ANNOUNCEMENT});
    setDeleteModal(false);
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const isCreateMode = isEmpty(selected); // If there is no selected announcement, then its a createMode otherwise editMode

    const formData = new FormData();

    formData.append('text', data.text);
    if (data.image) formData.append('image', data.image);
    if (!isCreateMode) formData.append('isActive', selected.isActive);

    if (isCreateMode) await dispatch(creatAnnouncement(formData));
    else await dispatch(updatedAnnouncement(formData, selected.id));

    setIsLoading(false);
    closeModal();
  };

  const handleDelete = async (id) => {
    setIsLoading(true);

    await dispatch(deleteAnnouncement(id));
    setIsLoading(false);

    closeDeleteModal();
  };

  return (
    <>
      <AnnouncementsTable
        openModal={openModal}
        openDeleteModal={openDeleteModal}
      />

      <AddEditModal
        onHide={closeModal}
        onSubmit={handleSubmit}
        show={announcementModal}
        loading={isLoading}
      />

      <DeleteModal
        onHide={closeDeleteModal}
        onConfirm={handleDelete}
        show={deleteModal}
        loading={isLoading}
      />

      <ToastContainer />
    </>
  );
};

export default Announcements;
