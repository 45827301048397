import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import AnnouncementModal from './AnnouncementModal';

import {orderAnnouncements} from '../../../utils/announcementsUtils';

import classes from './Announcements.module.css';

const AnnouncementModals = () => {
  const {list: announcements, order} = useSelector(
    (state) => state.announcements
  );
  const [remainingAnnouncements, setRemainingAnnouncements] = useState([]);

  const updateViewed = (viewedId) => {
    setRemainingAnnouncements((prevAnnouncements) =>
      prevAnnouncements.filter((announcement) => announcement.id !== viewedId)
    );
  };

  const cancelAllAnnouncements = () => setRemainingAnnouncements([]);

  useEffect(() => {
    const orderedAnnouncements = orderAnnouncements(order, announcements);

    setRemainingAnnouncements(orderedAnnouncements);
  }, [announcements, order]);

  const announcement = remainingAnnouncements[0];

  return (
    remainingAnnouncements.length > 0 && (
      <section className={classes.modals__wrapper}>
        <AnnouncementModal
          key={announcement.id}
          announcement={announcement}
          onAcknowledge={updateViewed}
          cancelAll={cancelAllAnnouncements}
        />
      </section>
    )
  );
};

export default AnnouncementModals;
