import React from 'react';

const PlainTextConverter = ({htmlString}) => {
  const convertToPlainText = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent;
  };

  const plainText = convertToPlainText(htmlString);

  return <div>{plainText}</div>;
};

export default PlainTextConverter;
