import React, {useState, useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';

import LogoutPage from './Logout';
const Header = ({onNote}) => {
  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    // for header fix on scroll
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <div className={`header ${headerFix ? 'is-fixed' : ''}`}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="container d-block my-0">
              <div className="d-flex align-items-center justify-content-sm-between justify-content-end">
                <div className="header-left"></div>
                <ul className="navbar-nav header-right">
                  <li>
                    <LogoutPage />
                    {/* <Dropdown className=" header-profile2 ">
                      <Dropdown.Toggle
                        as="a"
                        className={`nav-link i-false cursor-pointer `}
                        id="droptoggle1"
                        //onClick={DropBtnblog()}
                      >
                        <div className="header-info2 d-flex align-items-center">
                          <img alt="" />
                          <div className="d-flex align-items-center sidebar-info">
                            <div>
                              <h6 className="font-w500 mb-0 ms-2">Joshua</h6>
                            </div>
                            <i className="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-end"></Dropdown.Menu>
                    </Dropdown> */}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
