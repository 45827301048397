import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {Field, Form, Formik} from 'formik';
import swal from 'sweetalert';
import {useSelector, useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {
  createProductAction,
  booleanAction,
  updateProductAction,
  editableProductAction,
  responseErrorAction,
} from '../../../../store/actions/ProductActions';
import imageCompression from 'browser-image-compression';
import MultiSelect from '../../form/MultiSelect';

const productSchema = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').required('Required'),
  categoryId: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .test({
      message: 'Please select at least one category',
      test: (arr) => arr.length > 0,
    }),
  price: Yup.number().min(0, "Can't be Empty").required(),
});

export default function ProductsAddEdit() {
  const allCategories = useSelector((state) => state.categories.categories);
  const expiryAction = useSelector((state) => state.products.booleanAction);
  const editContentData = useSelector(
    (state) => state.products.editableProduct
  );
  const responseError = useSelector((state) => state.products.responseError);
  const [imageUpload, setImageUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];

    const optionsCompress = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 320,
      useWebWorker: true,
    };
    imageCompression(image, optionsCompress).then((compressedImg) => {
      setImageUpload(compressedImg);
    });
  };

  useEffect(() => {
    if (responseError?.success == false) {
      console.log(responseError);
      swal(
        `Error ${
          responseError?.err?.statusCode ? responseError.err.statusCode : ''
        }`,
        `${responseError?.err?.message ? responseError.err.message : ''}`,
        'error'
      );
      setIsLoading(false);
      dispatch(responseErrorAction({}));
    }
    if (expiryAction) {
      navigate('/dashboard/products');
      swal(`Updated`, ``, 'success');
      dispatch(booleanAction(false));
    }
  }, [expiryAction, responseError]);

  let categoryIdInitialValueForEdit = [];

  if (editContentData) {
    categoryIdInitialValueForEdit = editContentData.categoryId.map(
      (categoryId) => {
        const category = allCategories.find((c) => c.id === categoryId);
        return {label: category.title, value: category.id};
      }
    );
  }

  return (
    <>
      <div className="row mb-3">
        {editContentData ? (
          <h1 className="display-6">Update Item: {editContentData.title}</h1>
        ) : (
          <h1>Add New Item</h1>
        )}
        <hr className="text-secondary" />
      </div>
      <Formik
        initialValues={{
          title: editContentData ? editContentData.title : '',
          price: editContentData ? editContentData.price : 0,
          categoryId: editContentData ? categoryIdInitialValueForEdit : [],
          available: editContentData ? editContentData.available : true,
          willBeInStockOn: editContentData
            ? editContentData.willBeInStockOn?.substring(0, 10)
            : '',
          notes: editContentData ? editContentData.notes : '',
          isInclude: editContentData ? editContentData.isInclude : true,
          image: '',
        }}
        validationSchema={productSchema}
        onSubmit={async (values) => {
          setIsLoading(true);
          if (values.title !== '') {
            const formData = new FormData();
            if (editContentData) {
              for (const [key, value] of Object.entries(values)) {
                if (key === 'categoryId') {
                  formData.set(key, JSON.stringify(value));
                } else if (key == 'image' && imageUpload) {
                  formData.set('image', imageUpload);
                } else {
                  formData.set(key, value);
                }
              }
              formData.delete('title');
              dispatch(updateProductAction(formData, editContentData.id));
              setImageUpload(null);
            } else {
              for (const [key, value] of Object.entries(values)) {
                if (key == 'image' && imageUpload) {
                  formData.set('image', imageUpload);
                } else if (key === 'categoryId') {
                  formData.set(key, JSON.stringify(value));
                } else {
                  formData.set(key, value);
                }
              }
              dispatch(createProductAction(formData));
              setImageUpload(null);
            }
          } else {
            setIsLoading(false);
            swal('Oops', 'Title is Required!', 'error');
          }
        }}
      >
        {({errors, touched, values}) => (
          <Form encType="multipart/form-data">
            <div className="row g-3">
              <div className="col-md-4">
                <label htmlFor="title" className="form-label">
                  Item Title
                </label>
                <Field
                  id="title"
                  className="form-control"
                  autoComplete="off"
                  name="title"
                />
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>
              <div className="col-md-4">
                <label htmlFor="categoryId" className="form-label">
                  Category
                </label>
                <Field
                  as={MultiSelect}
                  id="categoryId"
                  name="categoryId"
                  options={allCategories.map((category) => {
                    return {label: category.title, value: category.id};
                  })}
                />
                {errors.categoryId && touched.categoryId ? (
                  <div className="text-danger">{errors.categoryId}</div>
                ) : null}
              </div>
              <div className="col-md-2">
                <label htmlFor="price" className="form-label">
                  Price
                </label>

                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                  <Field
                    id="price"
                    className="form-control"
                    autoComplete="off"
                    name="price"
                    type="number"
                  />
                </div>
                {errors.price && touched.price ? (
                  <div className="text-danger">{errors.price}</div>
                ) : null}
              </div>
              <div className="col-md-4">
                <label htmlFor="available" className="form-label">
                  Status
                </label>
                <div
                  className="form-check form-switch"
                  style={{marginTop: '9px'}}
                >
                  <Field
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="available"
                    name="available"
                  />
                  <label className="form-check-label">Stock/Out of Stock</label>
                </div>
                {!values?.available && (
                  <div>
                    <label className="form-check-label">
                      Expected Return Date
                    </label>
                    <Field
                      id="willBeInStockOn"
                      className="form-control"
                      autoComplete="off"
                      name="willBeInStockOn"
                      type={'date'}
                    />
                    <label className="form-check-label">Notes</label>
                    <Field
                      id="notes"
                      className="form-control"
                      autoComplete="off"
                      name="notes"
                    />
                  </div>
                )}
              </div>
              <div className="col-md-2">
                <label htmlFor="available" className="form-label">
                  Is Included?
                </label>
                <div
                  className="form-check form-switch"
                  style={{marginTop: '9px'}}
                >
                  <Field
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="isInclude"
                    name="isInclude"
                  />
                  <label className="form-check-label">Yes/No</label>
                </div>
              </div>
              <div className="col-4">
                <label htmlFor="image" className="form-label">
                  Item Image
                </label>
                {editContentData?.image ? (
                  <img
                    className="rounded border mb-2"
                    width="120"
                    height="120"
                    src={editContentData.image}
                    style={{objectFit: 'cover', display: 'block'}}
                    alt=""
                  />
                ) : (
                  ''
                )}
                <input
                  id="imageHolder"
                  type="file"
                  className="form-control"
                  autoComplete="off"
                  name="imageHolder"
                  // onChange={(e) => setImageUpload(e.target.files[0])}
                  onChange={(event) => handleCompressedUpload(event)}
                />
                <Field
                  id="image"
                  type="file"
                  className="form-control"
                  autoComplete="off"
                  name="image"
                  onChange={(e) => setImageUpload(e.target.files[0])}
                  style={{position: 'absolute', visibility: 'hidden'}}
                />
              </div>
              <hr className="text-secondary my-5 mb-1 d-block" />

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-secondary shadow"
                  style={{overflow: 'hidden', position: 'relative'}}
                  disabled={isLoading ? true : false}
                >
                  {editContentData ? 'Update Item' : 'Add Item'}
                  {isLoading ? (
                    <span className="lds-ripple">
                      <span></span>
                      <span></span>
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
