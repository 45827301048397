import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import CategoriesReducer from './reducers/CategoriesReducer';
import ProductsReducer from './reducers/ProductsReducer';
import ShopReducer from './reducers/ShopReducer';
import OrdersReducer from './reducers/OrdersReducer';
import thunk from 'redux-thunk';
import {AuthReducer} from './reducers/AuthReducer';
import CouponsReducer from './reducers/CouponsReducer';
import AnnouncementsReducer from './reducers/AnnouncementsReducer';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  categories: CategoriesReducer,
  shop: ShopReducer,
  auth: AuthReducer,
  products: ProductsReducer,
  orders: OrdersReducer,
  coupons: CouponsReducer,
  announcements: AnnouncementsReducer,
  //form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
